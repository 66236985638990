import { Button, Loader, Select } from 'semantic-ui-react'
import 'react-phone-number-input/style.css'


import LogoDiana from '../../Assets/LogoDiana.svg'

import FooterLeft from '../../Assets/Footer_Left.png'
import FooterRight from '../../Assets/Footer_Right.png'

import './Invitation.scss'
import { gql, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { INVITATION_CONFIRM } from '../connections'
import { attemptLogin } from '../../Store/Actions'

export const USER_SENDSMS = gql`
  mutation UserSendSMS($UserSendSMSInput: UserSendSMSInput!) {
    UserSendSMS(input: $UserSendSMSInput) {
      success
      state
      text
    }
  }
`

type InvitationConfirmInput = {
  answer: boolean
  adults?: number
  childrens?: number
}

export default function Invitation() {
  const { 
    maxAdults,
    maxChildrens,
    message,
    confirmed
  } = useSelector((state: any) => state.userInfo)

  const token = useSelector((state: any) => state.token)

  const [showMap, setShowMap] = useState<boolean>(false)
  const [enabled, setEnabled] = useState<boolean>(maxAdults === 1 && (maxChildrens >= 0 && maxChildrens <= 1) ? true : false)

  const [selectedAdults, setSelectedAdults] = useState<number>()
  const [selectedChildrens, setSelectedChildrens] = useState<number>()

  const dispatch = useDispatch()

  const [confirm, { data, loading, called }] = useMutation(INVITATION_CONFIRM, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  })

  const click = (answer: boolean) => {
    const InvitationConfirmInput: InvitationConfirmInput = {
      answer
    }

    if (answer) {
      if (selectedAdults !== undefined) {
        InvitationConfirmInput.adults = selectedAdults + 1
      }
  
      if (selectedChildrens !== undefined) {
        InvitationConfirmInput.childrens = selectedChildrens + 1
      }
    }

    if (!called) {
      confirm({
        variables: {
          InvitationConfirmInput
        }
      })
    }
  }

  // Toggle del mapa
  useEffect(() => {
    if (showMap) {
      window.scrollTo(0, document.body.scrollHeight)
    } else {
      window.scrollTo(0, 0)
    }
   }, [showMap])

  // Actualiza el botón enabled según condiciones
  useEffect(() => {
    const screenAdults = maxAdults > 1
    const screenChildrens = maxChildrens > 1
    let _enabled = (!screenAdults ? 1 : 0) + (!screenChildrens ? 1 : 0)

    if (screenAdults && selectedAdults !== undefined && selectedAdults >= 0) {
      _enabled++
    }
    if (maxChildrens > 1 && selectedChildrens !== undefined && selectedChildrens >= 0) {
      _enabled++
    }
    setEnabled(_enabled >= 2)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAdults, selectedChildrens])

  // Actualización de la invitación
  useEffect(() => {
    if (data?.InvitationConfirm?.success) {
      dispatch<any>(
        attemptLogin({
          token: data?.InvitationConfirm?.token,
          userdata: data?.InvitationConfirm?.invitation
        })
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className='Invitation'>
      {/* Imagen de fondo. */}
      <div className="Invitation__Gradient" />
      <div className="Invitation__Background" />
      {/* Invitación principal. */}
      <div className='Invitation__Wrapper'>
        {/* Pre título */}
        <div className="Invitation__Curved">
          <svg width="354" height="64" viewBox="0 0 370 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="curve" d="M0 63.5815C4.00339 61.758 65.5556 34.6448 178.751 35.0035C290.146 35.3622 349.696 61.9972 354 64" fill="transparent" />
            <text width="500">
              <textPath xlinkHref='#curve'>
                ¡Es tiempo de festejar!
              </textPath>
            </text>
          </svg>
        </div>
        {/* Logo principal */}
        <div className="Invitation__Logo">
          <img src={LogoDiana} alt={`Logo de Evento de Diana Alondra`} />
        </div>
        {/* Bajada de logotipo */}
        <div className="Invitation__Starred">
          <div className="Invitation__Starred__Left" />
          <div className="Invitation__Starred__Content">
            celebrará sus 5 años
          </div>
          <div className="Invitation__Starred__Right" />
          <div className="Invitation__Starred__Background" />
        </div>
        {/* Detalles del Calendario */}
        <div className="Invitation__Date">
          <div className="Invitation__Date__Day">
            Sábado <br />15 de Julio
          </div>
          <div className="Invitation__Date__Hour">
            12:00 m.
          </div>
        </div>
        {/* Lugar del Evento */}
        <div className="Invitation__Place">
          {confirmed === 0 && <span>
            ¿Te sumas?
            <small>
              Es importante para Diana tu presencia, confirma tu asistencia al evento.
            </small>
          </span>}
          {message?.trim().length > 0 && <span style={{
            marginTop: '16px'
          }}>
            Diana te envía un mensaje:
            <small>{message}</small>
          </span>}
          {confirmed === 0 && <>{loading ? <Loader active inline size='big' style={{ marginTop: 16 }} /> : <>
          <div className="Invitation__Place__Selector">
            {maxAdults > 1 && <div className="Invitation__Place__Selector__Field">
              <small>Queremos que vengan todos. <strong>¿Cuántos adultos/as vendrán?</strong></small>
              <Select value={selectedAdults} onChange={(e, data) => {
                // @ts-ignore
                setSelectedAdults(parseInt(data.value))
              }} size='tiny' id="adults" placeholder={`Selecciona entre 1 y ${maxAdults} adultos...`} options={[
              ...Array.from(Array(maxAdults).keys()).map((item, index) => ({
                key: `adult-${index}`,
                value: index,
                text: `${index + 1}`
              }))
            ]} /></div>}
            {maxChildrens > 1 && <div className="Invitation__Place__Selector__Field">
              <small>La infancia es la protagonista. <strong>¿Cuántos niños/as vendrán?</strong></small>
              {/* <small>Niños</small> */}
              <Select value={selectedChildrens} onChange={(e, data) => {
                // @ts-ignore
                setSelectedChildrens(parseInt(data.value))
              }} size='tiny' placeholder={`Selecciona entre 1 y ${maxChildrens} niños...`} options={[
              ...Array.from(Array(maxChildrens).keys()).map((item, index) => ({
                key: `children-${index}`,
                value: index,
                text: `${index + 1}`
              }))
            ]} /></div>}
          </div>
          <div className="Invitation__Place__Buttons">
            <Button disabled={!enabled} style={{ backgroundColor: '#3D8D60' }} color='green' size='large' fluid onClick={() => click(true)}>Sí</Button>
            <Button style={{ backgroundColor: '#CC5959' }} color='red' size='large' fluid onClick={() => click(false)}>No</Button>
          </div>
          </>}</>}
          {confirmed === 1 && <span style={{ marginTop: 16 }}>¡Te esperamos!</span>}
          {confirmed === -1 && <span style={{ marginTop: 16 }}>¡Te vamos a extrañar!<small>Recuerda que siempre serás bienvenido, y si cambias de opinión escríbenos a <a style={{ fontWeight: 'bold' }} href="mailto:evento@dianaalondra.cl">evento@dianaalondra.cl</a> o al WhatsApp <a style={{ fontWeight: 'bold' }} href="https://wa.me/56981237331">+56 9 8123 7331</a>.</small></span>}
          <span><small style={{ marginBottom: '8px' }}>la celebración será en: <br /></small>Espacio Eukaliptus<small>detrás de Colegio Versalles, Las Parcelas con Raúl Bitrán. Parcela 314.</small></span>
          <div className="Invitation__Place__Buttons">
            <Button fluid size='large' onClick={() => setShowMap(_v => !_v)}>{showMap ? 'Ocultar' : 'Ver'} mapa completo</Button>
          </div>
          {showMap && <iframe
            title="Mapa de Espacio Eukaliptus"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3457.7643053690035!2d-71.2111792!3d-29.928686899999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9691cb5360af1f77%3A0xd9d208f2e651b6cd!2sEspacio%20Eukaliptus!5e0!3m2!1ses-419!2scl!4v1687720562887!5m2!1ses-419!2scl"
            width="100%"
            height="375"
            style={{ border: 0, marginTop: '2rem' }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade" />}
        </div>
        {/* Espaciador para el Pié de Pagina */}
        <div className="Invitation__Clearfix" />
      </div>
      {/* Pié de página. */}
      <div className="Invitation__Footer">
        <img src={FooterLeft} alt="" />
        <img src={FooterRight} alt="" />
      </div>
    </div>
  )
}
