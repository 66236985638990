import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { Button, Header, Message } from 'semantic-ui-react'
import 'react-phone-number-input/style.css'
import Input from 'react-phone-number-input/input'
import { isValidPhoneNumber } from 'react-phone-number-input'

import animationData from '../../Assets/Cellphone.json'

import './EasyLoginRequired.scss'
import { gql, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { attemptLogin } from '../../Store/Actions'

export const USER_SENDSMS = gql`
  mutation UserSendSMS($UserSendSMSInput: UserSendSMSInput!) {
    UserSendSMS(input: $UserSendSMSInput) {
      success
      state
      text
      token
			invitation {
				id
				uuid
				guest
				guestRelation
				whatsAppCUS
				maxAdults
				maxChildrens
				group
				message
				confirmed
				adults
				childrens
			}
    }
  }
`

export default function EasyLoginRequired() {
  const [value, setValue] = useState<any>()
  const [nombr, setNombr] = useState<string>()
  const [enabled, setEnabled] = useState<boolean>(false)

  const [sendSMS, { data, loading, error }] = useMutation(USER_SENDSMS)

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const isValid = (value ? isValidPhoneNumber(value) : false) && !!(nombr && nombr?.trim().length >= 5)
    setEnabled(isValid)
  }, [value, nombr])

  useEffect(() => {
    if (data) {
      if (data?.UserSendSMS?.success) {
        const isLogged = dispatch<any>(
          attemptLogin({
            token: data.UserSendSMS.token,
            userdata: data.UserSendSMS.invitation
          })
        )
        if (isLogged) {
          history.push('/invitation')
        }
      }

      // if (data?.UserSendSMS?.state === 'GENERATE_SMS') {
      //   localStorage.setItem('app@login/isSent', 'true')
      //   setIsSend(true)
      // } else {
      //   localStorage.removeItem('app@login/isSent')
      //   setIsSend(false)
      //   alert('Ocurrió un error al solicitar el registro, intenta nuevamente.')
      // }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const click = () => {
    if (enabled) {
      sendSMS({
        variables: {
          UserSendSMSInput: {
            phone: value,
            name: nombr
          }
        }
      })
    }
  }

  return (
    <div className='EasyLoginRequired'>
    <div className='EasyLoginRequired__Wrapper'>
      <Header as='h2' icon>
        <Lottie
          options={{
            animationData,
            autoplay: true,
            loop: true
          }}
          height={156}
          width={256}
          style={{
            marginBottom: 32
          }}
        />
        <span>
          Inicio de Sesión obligatorio
        </span>
        <Header.Subheader
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 16
          }}
        >
          Hola, para ver la invitación interactiva, debes ingresar
          el <strong>número de teléfono</strong> al cuál fuiste invitado.
          <br />
          <br />
          Para el caso de las invitaciones familiares, debes ingresar el número de teléfono del <strong>padre o madre</strong> que recibió la invitación.
          <br />
          {error && <Message color='pink'>{error.message}</Message>}
          {<div className="ui large input" style={{
            flexDirection: 'column'
          }}>
            <Input
              international={true}
              style={{
                marginTop: 6
              }}
              placeholder="9 1234 6789"
              country='CL'
              defaultCountry='CL'
              autoFocus
              withCountryCallingCode={true}
              value={value}
              onChange={(val) => setValue(val)}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                    click()
                  }
              }}
              onSubmit={click}
              />
            <input
                placeholder='Código único de acceso...'
                value={nombr}
                style={{ marginTop: '8px' }}
                onChange={(e) => setNombr(e.target.value)}
                onSubmit={click}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    click()
                  }
                }}
              />
          </div>}
          <><br />
          <br />
            <Button loading={loading} onClick={click} size='large' disabled={!enabled} color='violet'>Continuar</Button>
          <br /></>
          <br /> Si tienes problemas comunícate con nosotros a{' '}
          <a href='mailto:evento@dianaalondra.cl'>
            <strong>evento@dianaalondra.cl</strong>
          </a>{' '}
          y te ayudaremos lo más pronto posible.
        </Header.Subheader>
        <small style={{
          fontSize: 14
        }}>
            <br />
            Una idea de ALNUS SpA &copy; 2015 - 2023
        </small>
      </Header>
        </div>
    </div>
  )
}
