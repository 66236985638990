import { gql } from '@apollo/client'

export const INVITATION_CONFIRM = gql`
mutation InvitationConfirm($InvitationConfirmInput: InvitationConfirmInput!) {
    InvitationConfirm(input: $InvitationConfirmInput) {
      success
      state
      text
			token
			invitation {
				id
				uuid
				guest
				guestRelation
				whatsAppCUS
				maxAdults
				maxChildrens
				group
				message
				confirmed
				adults
				childrens
			}
    }
  }
`

export const ON_NEW_QUESTION = gql`
subscription {
  OnNewQuestion {
    question {
      id
      title
      author
      createdAt
      Answers {
        id
      }
    }
  }
}
`

export const ON_ANSWER_QUESTION = gql`
subscription {
  OnAnswerQuestion {
    answer {
      id
      questionId
      questionAnswerId
      answer
      type
      authorId
      createdAt
      Author {
        id
        firstName
        lastName
        password
      }
    }
  }
}
`

export const USER_LOGIN = gql`
  mutation UserLogin($UserLoginInput: UserLoginInput!) {
    UserLogin(input: $UserLoginInput) {
      success
      state
      text
      token
      invitation {
				id
				uuid
				guest
				guestRelation
				whatsAppCUS
				maxAdults
				maxChildrens
				group
				message
				confirmed
				adults
				childrens
			}
    }
  }
`

export const QUESTION_ANSWER = gql`
mutation QuestionAnswer($QuestionAnswerInput: QuestionAnswerInput!) {
    QuestionAnswer(input: $QuestionAnswerInput) {
      success
  }
}
`

export const CREATE_QUESTION = gql`
mutation AdminCreateQuestion($AdminCreateQuestionInput: AdminCreateQuestionInput!) {
    AdminCreateQuestion(input: $AdminCreateQuestionInput) {
    	success
    	created	{
        id
        title
        author
        createdAt
      }
  }
}`


export const USER_MORE_ANSWERS = gql`
  query UserMoreAnswers($UserMoreAnswersInput: UserMoreAnswersInput!) {
    UserMoreAnswers(input: $UserMoreAnswersInput) {
      enabledViewMore
      answers {
        id
        questionId
        questionAnswerId
        answer
        type
        authorId
        createdAt
        Author {
          id
          firstName
          lastName
        }
        Answers {
          id
          questionId
          questionAnswerId
          answer
          type
          authorId
          createdAt
          Author {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const USER_ME = gql`
  query UserMe {
    UserMe {
      isAdmin
      list {
        id
        title
        author
        createdAt
        answered
        enabledViewMore
        Answers {
          id
          questionId
          questionAnswerId
          answer
          type
          authorId
          createdAt
          Author {
            id
            firstName
            lastName
          }
          Answers {
            id
            questionId
            questionAnswerId
            answer
            type
            authorId
            createdAt
            Author {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`

export const RECOVER_PASSWD = gql`
  mutation UserRecoverPasswd($UserRecoverPasswdInput: UserRecoverPasswdInput!) {
    UserRecoverPasswd(input: $UserRecoverPasswdInput) {
      success
    }
  }
`

export const USER_LEARNING_ROUTES = gql`
  query UserLearningRoutes {
    UserLearningRoutes {
      LearningRoute {
        id
        parentId
        name
        description
        icon
        sortOrder
        image
        slug
        Courses {
          id
          typeId
          learningRouteId
          sortOrder
          name
          description
          image
          slug
          href
          enabled
          times
          status {
            code
            name
          }
        }
      }
    }
  }
`

export const GET_ROUTES_LIST = gql`
  query getRoutesList($getRoutesListInput: getRoutesListInput!) {
    getRoutesList(input: $getRoutesListInput) {
      LearningRoute {
        id
        parentId
        name
        description
        icon
        sortOrder
        image
        slug
        Courses {
          id
          typeId
          learningRouteId
          sortOrder
          name
          description
          image
          slug
          href
          enabled
          times
          status {
            code
            name
          }
        }
      }
    }
  }
`

export const GET_COURSE_DETAIL = gql`
  query getCourseDetail($CourseDetailInput: CourseDetailInput!) {
    getCourseDetail(input: $CourseDetailInput) {
      Course {
        id
        typeId
        learningRouteId
        sortOrder
        name
        description
        image
        slug
        href
        enabled
        times
        qualify
        status {
          code
          name
        }
      }
      LearningResources {
        id
        typeId
        name
        description
        extendedDescription
        timeline
        image
        configuration
      }
      LearningRoute {
        id
        name
      }
      RelatedCourses {
        name
        href
      }
    }
  }
`

export const COURSE_QUALIFY = gql`
  mutation courseQualify($CourseQualifyInput: CourseQualifyInput!) {
    courseQualify(input: $CourseQualifyInput) {
      success
    }
  }
`

export const USER_REGISTER = gql`
  mutation UserRegister($UserRegisterInput: UserRegisterInput!) {
    UserRegister(input: $UserRegisterInput) {
      success
    }
  }
`

export const USER_VALIDATE_ACCOUNT = gql`
  mutation UserValidate($UserValidateInput: UserValidateInput!) {
    UserValidate(input: $UserValidateInput) {
      success
      token
    }
  }
`

export const USER_CHECKDOMAIN = gql`
  mutation CheckDomain($CheckDomainInput: CheckDomainInput!) {
    CheckDomain(input: $CheckDomainInput) {
      success
      text
      state
    }
  }
`
